/*
 use full screen, for app-like experience
*/
body {
    margin: 0;
    overflow-y: scroll;
}
#root {
    height: 100vh;
    width: 100vw;
    display: flex;
}

/*
* make text selectable
*/
.ioChat.nlux-AiChat-root {
    user-select: auto;
}

/*
 update colors to be closer to IO theme
*/
.ioChat.nlux-AiChat-root.nlux-theme-nova {
    --nlux-ChatRoom--BorderRadius: 2px;
    --nlux-ChatRoom--BorderWidth: 0px;
    --nlux-ChatRoom-Divider--BorderWidth: 0px;
    --nlux-ChatRoom--FontSize: 18px;
    --nlux-ChatRoom--LineHeight: 1.4;
    --nlux-ChatRoom--TextColor: black;
    
    --nlux-ChatItem-Avatar--BorderRadius: 50%;
    --nlux-ChatItem-Avatar--BorderWidth: 0px;
    --nlux-ChatItem-Avatar--Width: 40px;
    --nlux-ChatItem-Message-BubbleLayout--BorderWidth: 1px;
    --nlux-ChatItem-Message-BubbleLayout--BorderRadius: 2px;
    
    --nlux-CodeBlock--BorderWidth: 0px;
    --nlux-CodeBlock--BorderRadius: 2px;
    --nlux-CodeBlock-CopyButton--BorderWidth: 1px;
    --nlux-CodeBlock-CopyButton--BorderRadius: 2px;
    --nlux-copy-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill=""><path d="M0 0h24v24H0V0z" fill="none"/><path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"/></svg>');

    --nlux-AiMessage--BorderColor: black;
    --nlux-HumanMessage--BackgroundColor: #455a64;
    --nlux-HumanMessage--BorderColor: black;

    --nlux-Exceptions--BorderWidth: 2px;
    --nlux-Exceptions--BorderRadius: 2px;

    --nlux-PromptInput--BorderRadius: 0px;
    --nlux-PromptInput--BorderWidth: 1px;
    --nlux-PromptInput--BorderColor: #455a64;
    --nlux-PromptInput-Active--BorderColor: #37474f;
    --nlux-PromptInput-Disabled--BorderColor: #455a64;
    --nlux-PromptInput-Focus-Outline--Width: 2px;
    --nlux-PromptInput-Placeholder--TextColor: lightgray;

    --nlux-SubmitButton--BackgroundColor: #285D93;
    --nlux-SubmitButton--BorderColor: #285D93;
    --nlux-SubmitButton--BorderRadius: 2px;
    --nlux-SubmitButton-Active--BackgroundColor: #25598D;
    --nlux-SubmitButton-Active--BorderColor: #25598D;
    --nlux-SubmitButton-Disabled--BackgroundColor: #075E97;
    --nlux-SubmitButton-Disabled--BorderColor: #075E97;
    --nlux-SubmitButton-Focus-Outline--Width: 2px;
    
    --nlux-send-icon: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="48px" viewBox="0 0 24 24" width="48px" fill=""><path d="M0 0h24v24H0V0z" fill="none"/><path d="M10 9V5l-7 7 7 7v-4.1c5 0 8.5 1.6 11 5.1-1-5-4-10-11-11z"/></svg>');
    .nlux_sndIcn {
        max-width: 48px;
    }
    .nlux_sndIcn .snd_icn_ctn {
        background-color: white;
    }
}

/* 
 make conversations appear from the bottom-up
*/
.ioChat.nlux-AiChat-root>.nlux-chtRm-cntr>.nlux-chtRm-cnv-cntr>.nlux-chtRm-cnv-sgmts-cntr {
    display: flex;
    flex: 1 0;
    flex-direction: column;
}
.ioChat.nlux-AiChat-root .message-container-in-list:first-of-type {
    margin-top: auto;
}

/* make room for navbar */
.ioChat.nlux-AiChat-root  .nlux-chtRm-cntr {
    padding-top: 60px;
    height: calc(100% - 60px);
}

/* adjust welcome message position and avatar size */
.MuiDialogContent-root.nlux-comp-wlc_msg {
    margin-top: 0;
}
.nlux-comp-wlc_msg .nlux-comp-avtr {
    width: 200px;
    border-width: 0;
}

/* fix list indentation */
.nlux-comp-msg>.nlux-md-strm-root>.nlux-md-cntr :is(ul,ol) {
    padding-left: 30px;
}
/* fix image width */
.nlux-comp-msg>.nlux-md-strm-root>.nlux-md-cntr img {
    max-width: 100%;
}

/* align welcome message */
.nlux-comp-wlc_msg {
    margin-top: -40px;
    text-align: center;
}

/* change text color of welcome message */
.nlux-comp-wlc_msg_txt {
    color: white;
}

/* change text color of person's name in welcome message */
.nlux-comp-wlc_msg_prs_nm {
    color: white;
}

/* remove border from avatar */
.ioChat.nlux-AiChat-root .nlux-comp-avtr {
    border-width: 0 !important;
    /* not sure why important is needed here, we should be able to override with avatar specific vars */
}

/* move exceptions down */
.nlux-AiChat-root>.nlux-comp-exp_box {
    top: 50px;
}
